<template>
  <div id="doctor-profile">
    <div class="title3">{{ $localization('web-doctor-profile-my-profile') }}</div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>{{ $localization('web-doctor-profile-my-profile') }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="task"><i class="icon-task"></i></div>
    </div>
    <div class="main">
      <div class="profile">
        <div class="profile-top">
          <div class="profile-photo">
            <img :src="user.Image" alt="" />
          </div>
          <div class="profile-name">{{ user.FirstName }} {{ user.LastName }}</div>
        </div>
        <div class="profile-bottom">
          <div class="item" v-if="user.LivingAddress.District != null && user.LivingAddress.City != null">
            <i class="icon-location"></i><span>{{ user.LivingAddress.District }}, {{ user.LivingAddress.City }}</span>
          </div>
          <div class="item" v-if="user.Email != null">
            <i class="icon-message"></i><span>{{ user.Email }}</span>
          </div>
          <div class="item">
            <i class="icon-mobile"></i><span>{{ user.PhoneNumber | VMask("+## ### ### ## ##") }}</span>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="address">
          <p>{{ $localization('web-doctor-profile-address') }}</p>
          <div class="info">
            <label class="data1">{{ $localization('web-doctor-profile-address') }}</label>
            <label class="data2">{{ user.LivingAddress.Address }}</label>
          </div>
          <div class="info">
            <label class="data1">{{ $localization('web-doctor-profile-city') }}</label>
            <label class="data2">{{ user.LivingAddress.City }}</label>
          </div>
          <div class="info">
            <label class="data1">{{ $localization('web-doctor-profile-district') }}</label>
            <label class="data2">{{ user.LivingAddress.District }}</label>
          </div>
          <!-- <div class="info">
            <label class="data1">Posta Kodu</label>
            <label class="data2">{{ user.LivingAddress.ZipCode }}</label> 
            zip code eklenecek backende
          </div> -->
        </div>
        <div class="documents-box">
          <div class="title">
            <div>{{ $localization('web-doctor-profile-my-documents') }}</div>
          </div>
          <div class="profile-slider owl-theme owl-carousel" v-if="user.Attachments.length > 0">
            <div class="slider-item" v-for="file in user.Attachments" :key="file.ID">
              <div class="doc-card">
                <div class="doc-information">
                  <div class="top-information">
                    <div class="checked"><i class="icon-checked"></i></div>
                    <el-dropdown>
                      <span class="el-dropdown-link actions-menu">
                        <i class="icon-dots"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          <span style="display: block; width: 100%" v-on:click="generatePresignedUrl(file)">{{
                            $localization('web-doctor-profile-show-file') }}</span>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>

                  <div class="pdf">
                    <img :src="getExtension(file.Extension)" width="120" alt="" />
                  </div>
                  <div class="pdf-bottom">{{ file.FilenameReal }}</div>
                  <div class="bottom-information">
                    <div class="file-size">
                      {{ fileSize(file.FileSizeInMb) }}
                    </div>
                    <!-- <div class="date">{{ file.CreatedDateTime }}</div>
                    backende eklendikten sonra gelecek -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="empty-file" v-else>
            <div class="lottie-container appointment">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">{{ $localization('web-doctor-profile-no-file-found') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenWidth: 0,
    };
  },
  methods: {
    async generatePresignedUrl(item) {
      var res = await this.$store.dispatch("generatePreSignedUrl", {
        ObjectKey: item.ObjectKey,
      });
      if (!res.HasError) {
        window.open(res.Data.Url, "_blank");
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  beforeMount() {
    window.jQuery(document).ready(function () {
      window.jQuery(".profile-slider").owlCarousel({
        items: 1,
        margin: 30,
      });
    });
  },
};
</script>

<style lang="less">
#doctor-profile {
  padding: 42px 30px;

  .title3 {
    font-weight: 700;
    font-size: 30px;
    color: #44566c;
    line-height: 35px;
    padding-bottom: 22px;
  }

  .main {
    display: flex;
    gap: 30px;

    @media screen and (max-width: 991px) {
      flex-direction: column;

      .profile,
      .middle {
        width: 100% !important;
      }
    }

    .profile-name {
      font-weight: 500;
      font-size: 30px;
      color: #44566c;
    }

    .profile {
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      background-color: #fff;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
    }

    .profile-top {
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
    }

    .profile-bottom {
      background-color: #f8fafb;
      padding: 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 0.4rem;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #44566c;

        &> :last-child {
          min-width: 150px;
        }
      }

      i {
        color: #8697ab;
      }
    }

    .profile-photo {
      width: 220px;
      height: 220px;
      border-radius: 40px;
      overflow: hidden;

      img {
        display: block;
        border-radius: 40px;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        box-shadow: 0px 2px 4px rgb(169 194 209 / 5%), 0px 12px 16px rgb(169 194 209 / 10%);
      }
    }

    .edit {
      background: #54d0c8;
      border-radius: 50px;
      box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);

      span {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 0.4rem;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #fff;
        border: none;
      }
    }
  }

  .middle {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .address {
      background-color: #fff;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 1rem;

      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        color: #44566c;
      }

      .info {
        display: flex;
        justify-content: space-between;
      }
    }

    .icon-task {
      width: 22px;
      height: 22px;
      background-color: #44566c;
      float: right;
    }

    .data1 {
      font-weight: 400;
      font-size: 15px;
      color: #8697a8;
    }

    .data2 {
      color: #44566c;
      font-weight: 500;
      font-size: 15px;
    }

    .documents-box {
      height: 299px;
      background-color: #fff;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      border-radius: 15px;
      padding: 25px;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;

        a {
          font-weight: 400;
          font-size: 15px;
          text-decoration: none;
          color: #54d0c8;
        }

        div {
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: #44566c;
        }
      }
    }

    .doc-card {
      overflow: hidden;
      background-color: #fff;
      border-radius: 15px;
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      box-shadow: 0px 4px 32px rgba(169, 194, 209, 0.05), 0px 2px 16px rgba(169, 194, 209, 0.05);
    }

    .doc-information {
      display: flex;
      flex-direction: column;
      padding: 15px;
      width: 100%;

      .pdf {
        align-self: center;
        padding-bottom: 5px;

        img {
          width: 120px;
          height: 80px;
        }
      }

      .pdf-bottom {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #44566c;
        align-self: center;
      }

      .top-information {
        display: flex;
        justify-content: space-between;

        .icon-dots {
          transform: rotate(-90deg);
        }
      }

      .bottom-information {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #8697a8;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .owl-theme .owl-dots .owl-dot:focus span {
    background: #54d0c8;
  }

  .owl-theme .owl-dots span {
    width: 7px;
    height: 7px;
  }
}</style>
